import React from 'react';
import type { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';

import { Heading } from '@chakra-ui/core';
import BackgroundImage from 'gatsby-background-image';

import withSEO from 'components/_hoc/withSEO';
import { ButtonChakraLink } from 'components/_lib/Links';

const SPACE_IMAGE_URL = `${process.env.AMAZON_WEB_SERVICES_S3_URL}/images/space.png`;

// Custom styled Gatsby background image
const StyledBackgroundImage = styled(BackgroundImage)`
  background-position: top;
  background-size: cover;
  backgroundrepeat: no-repeat;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

/**
 * 404 not found page
 */
const NotFoundPage: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      spaceImage: file(relativePath: { eq: "images/space.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <StyledBackgroundImage fluid={data.spaceImage.childImageSharp.fluid}>
      <Heading color="white" mb={8}>
        Not Found
      </Heading>
      <Heading color="white" size="md">
        Uh oh, looks like you&apos;re lost...
      </Heading>
      <ButtonChakraLink href={process.env.LANDING_PAGE_URL} mt={8} size="lg">
        Return to Earth
      </ButtonChakraLink>
    </StyledBackgroundImage>
  );
};

export default withSEO(NotFoundPage, {
  title: 'Not Found',
  image: SPACE_IMAGE_URL,
});
